









































































import Vue from "vue";

export default Vue.extend({
  name: "PricingComponent",
  computed: {
    isRegularPrice(): boolean {
      return false;
    }
  }
});
